:root {
	--fixed-or-static: fixed;
	--sixty-nine-or-zero: 69pt;
	--flex-or-block-display: flex;
	--big-size: 2rem;
	--half-or-full: 50%;
	--left-or-center: left;
	--right-or-center: right;
	--ten-pt-or-zero: 10pt;
	--zero-or-twenty-pt: 0;
	--hundred-px-or-seventy: 100px;
}

@media (max-width: 480px) {
	:root {
		--fixed-or-static: static;
		--sixty-nine-or-zero: 0;
		--flex-or-block-display: block;
		--big-size: 2.5rem;
		--half-or-full: 100%;
		--left-or-center: center;
		--right-or-center: center;
		--ten-pt-or-zero: 0;
		--zero-or-twenty-pt: 20pt;
		--hundred-px-or-seventy: 70px;
	}
}