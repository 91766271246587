@import-normalize;

:root {
	color-scheme: light dark;
}

@import './responsive.css';
@import './color-palette.css';

* {
	box-sizing: border-box;
}

html,
body {
	width: 100vw;
	min-height: 100vh;
	margin: 0;
	padding: 0;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: var(--a-color);
	color: var(--b-color);
	margin-bottom: var(--sixty-nine-or-zero);
}

div#root {
	width: 100%;
	height: 100%;
}
