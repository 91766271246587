body,
body.light {
	--a-color: #F7F7FF;
	--b-color: #131112;
	--c-color: #ffc857;
	--d-color: #A0BBA8;
	--e-color: #798086;
}

body.dark {
	--a-color: #131112;
	--b-color: #F7F7FF;
	--c-color: #FFDD99;
	--d-color: #4E6E58;
}
